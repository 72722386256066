import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateHelper, DateTimeUnit } from '../../helpers/date.helper';
import { Subscription } from 'rxjs';
import { FormHelper } from '../../helpers/form.helper';

export function controlProviderFactory(container: ControlContainer) {
  return container;
}

@Component({
  selector: 'shared-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DateTimePickerComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimePickerComponent implements ControlValueAccessor, OnDestroy {
  @Input() dateTimeDelimiter = 'at';
  @Input() showTime = true;

  disabled: boolean;
  private onChange = (dateTime: Date) => {};
  private onTouched = () => {};
  private touched = false;
  private subscription: Subscription;

  form = this.formBuilder.nonNullable.group({
    date: [],
    time: [],
  });

  // calendar min max dates
  today = new Date();
  endOfNextYear = DateHelper.addTime(DateHelper.endOfYear(new Date()), 50, DateTimeUnit.Year);

  get f() {
    return this.form.controls;
  }

  constructor(private formBuilder: FormBuilder) {
    this.subscription = this.form.valueChanges.subscribe(() => this.calculateDateTime());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private calculateDateTime() {
    this.markAsTouched();
    const dateTime = DateHelper.joinDateTime(this.f.date.value, this.f.time.value);
    this.onChange(dateTime);
  }

  writeValue(dateTime: Date): void {
    const split = DateHelper.splitDateTime(dateTime);
    this.form.setValue({ date: split.date, time: split.time });
  }

  registerOnChange(onChange: (dateTime: Date) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    FormHelper.setEnabled(this.form, !isDisabled);
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
