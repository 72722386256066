import { Injectable } from '@angular/core';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { MonitoringResource, ResourceConfiguration } from '../../types/monitoring/monitoring-resource.type';
import { ResourceSort } from '../../types/monitoring/resource-sort.type';
import { ResourcesStatusInfo } from '../../types/monitoring/resource-status-info.type';
import { ResourceFilter } from '../../types/monitoring/resources-filter.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class ResourcesApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/resources`;
  }

  async getResources(pageSize: number, pageIndex: number, filter: ResourceFilter = null, sortBy: ResourceSort = null): Promise<MonitoringResource[]> {
    const result = await this.get<MonitoringResource[]>('', { pageSize, pageIndex, ...filter, ...sortBy });
    result.forEach(s => ApiConversionHelper.convertMonitoringResource(s));
    return result;
  }

  async getResourcesStatus(): Promise<ResourcesStatusInfo> {
    return await this.get<ResourcesStatusInfo>('statuses');
  }

  async updateResourceConfiguration(resourceId: string, configuration: ResourceConfiguration): Promise<void> {
    return await this.patch(resourceId, configuration );
  }

  async setResourceIsEnabled(resourceId: string, isEnabled: boolean): Promise<void> {
    return await this.patch(`${resourceId}/is-enabled`, isEnabled);
  }


  async setResourceIsQuarantined(resourceId: string, isQuarantined: boolean): Promise<void> {
    return await this.patch(`${resourceId}/is-quarantined`, isQuarantined);
  }

  async setResourceResourceGroups(resourceId: string, resourceGroupsIds: string[]): Promise<void> {
    return await this.patch(`${resourceId}/resource-groups`, resourceGroupsIds);
  }
}
