import { appWindowError404Icon } from './app-window-error-404';
import { arrowButtonDownIcon } from './arrow-button-down';
import { arrowButtonLeftIcon } from './arrow-button-left';
import { arrowButtonRightIcon } from './arrow-button-right';
import { arrowButtonUpIcon } from './arrow-button-up';
import { arrowDoubleLeftIcon } from './arrow-double-left';
import { arrowDoubleRightIcon } from './arrow-double-right';
import { arrowDownIcon } from './arrow-down';
import { arrowLeftLightIcon } from './arrow-left-light';
import { arrowLeftIcon } from './arrow-left';
import { arrowRightLightIcon } from './arrow-right-light';
import { arrowThickCircleLeftIcon } from './arrow-thick-circle-left';
import { arrowUpIcon } from './arrow-up';
import { calendarIcon } from './calendar';
import { canceledCircleIcon } from './canceled-circle';
import { chevronDownIcon } from './chevron-down';
import { chevronUpIcon } from './chevron-up';
import { clockCircleIcon } from './clock-circle';
import { closeIcon } from './close';
import { codeIcon } from './code';
import { collapseIcon } from './collapse';
import { copyPasteIcon } from './copy-paste';
import { copyIcon } from './copy';
import { costAnalysisIcon } from './cost-analysis';
import { currencyDollarIcon } from './currency-dollar';
import { delete24Icon } from './delete-24';
import { deleteIcon } from './delete';
import { desktopComputerPcIcon } from './desktop-computer-pc';
import { doneCircleBigIcon } from './done-circle-big';
import { doneCircleIcon } from './done-circle';
import { downloadIcon } from './download';
import { edit24Icon } from './edit-24';
import { editIcon } from './edit';
import { emptyIcon } from './empty';
import { errorCircleIcon } from './error-circle';
import { expandIcon } from './expand';
import { fileCodeCheckIcon } from './file-code-check';
import { fileIcon } from './file';
import { filterIcon } from './filter';
import { fitToScreenIcon } from './fit-to-screen';
import { headerCloseIcon } from './header-close';
import { infoLogIcon } from './info-log';
import { informationCircleIcon } from './information-circle';
import { jumpToIcon } from './jump-to';
import { layersStackedIcon } from './layers-stacked';
import { linkIcon } from './link';
import { lockClosedIcon } from './lock-closed';
import { lockOpenIcon } from './lock-open';
import { lockIcon } from './lock';
import { menuDotsIcon } from './menu-dots';
import { messagesBubleAddIcon } from './messages-buble-add';
import { newEmailIcon } from './new-email';
import { noConnectionCircleIcon } from './no-connection-circle';
import { noConnectionIcon } from './no-connection';
import { noDataIcon } from './no-data';
import { organizationIcon } from './organization';
import { parametersIcon } from './parameters';
import { pasteIcon } from './paste';
import { playIcon } from './play';
import { plusIcon } from './plus';
import { questionmarkIcon } from './questionmark';
import { renameIcon } from './rename';
import { repeatIcon } from './repeat';
import { resourceIcon } from './resource';
import { revertIcon } from './revert';
import { searchIcon } from './search';
import { sendEmailFlyIcon } from './send-email-fly';
import { sendEmailIcon } from './send-email';
import { sequenceIcon } from './sequence';
import { serverError500Icon } from './server-error-500';
import { sessionListIcon } from './session-list';
import { settingsIcon } from './settings';
import { shrinkIcon } from './shrink';
import { simpleIcon } from './simple';
import { sortIcon } from './sort';
import { stopIcon } from './stop';
import { tagIcon } from './tag';
import { timeIcon } from './time';
import { timerIcon } from './timer';
import { uploadedCircleIcon } from './uploaded-circle';
import { uploadedIcon } from './uploaded';
import { viewOff2Icon } from './view-off-2';
import { viewOffIcon } from './view-off';
import { viewIcon } from './view';
import { warningIcon } from './warning';
import { webhookIcon } from './webhook';
import { zoomInIcon } from './zoom-in';
import { zoomOutIcon } from './zoom-out';
export const sharedIcons = [appWindowError404Icon, arrowButtonDownIcon, arrowButtonLeftIcon, arrowButtonRightIcon, arrowButtonUpIcon, arrowDoubleLeftIcon, arrowDoubleRightIcon, arrowDownIcon, arrowLeftLightIcon, arrowLeftIcon, arrowRightLightIcon, arrowThickCircleLeftIcon, arrowUpIcon, calendarIcon, canceledCircleIcon, chevronDownIcon, chevronUpIcon, clockCircleIcon, closeIcon, codeIcon, collapseIcon, copyPasteIcon, copyIcon, costAnalysisIcon, currencyDollarIcon, delete24Icon, deleteIcon, desktopComputerPcIcon, doneCircleBigIcon, doneCircleIcon, downloadIcon, edit24Icon, editIcon, emptyIcon, errorCircleIcon, expandIcon, fileCodeCheckIcon, fileIcon, filterIcon, fitToScreenIcon, headerCloseIcon, infoLogIcon, informationCircleIcon, jumpToIcon, layersStackedIcon, linkIcon, lockClosedIcon, lockOpenIcon, lockIcon, menuDotsIcon, messagesBubleAddIcon, newEmailIcon, noConnectionCircleIcon, noConnectionIcon, noDataIcon, organizationIcon, parametersIcon, pasteIcon, playIcon, plusIcon, questionmarkIcon, renameIcon, repeatIcon, resourceIcon, revertIcon, searchIcon, sendEmailFlyIcon, sendEmailIcon, sequenceIcon, serverError500Icon, sessionListIcon, settingsIcon, shrinkIcon, simpleIcon, sortIcon, stopIcon, tagIcon, timeIcon, timerIcon, uploadedCircleIcon, uploadedIcon, viewOff2Icon, viewOffIcon, viewIcon, warningIcon, webhookIcon, zoomInIcon, zoomOutIcon];
