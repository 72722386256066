@if (filterDefinition$ | async; as filterDefinition) {
  <div class="flex flex-row flex-gap-16-8">
    @for (filter of filterDefinition.filters; track $index) {
      <div class="select-content-width">
        @if (filter.type === FilterItemType.Select || filter.type === FilterItemType.MultiSelect) {
          <shared-select-with-search
            [multiple]="filter.type === FilterItemType.MultiSelect"
            [items]="filterSelectionMap.get(filter.name).items"
            [fixedItems]="filterSelectionMap.get(filter.name).fixedItems"
            [selection]="filterSelectionMap.get(filter.name).selection"
            [selectAllText]="filter.allItemsLabel"
            [placeholder]="filter.placeholder"
            (selectionChange)="onFilterValueChanged($event, filter)"
            (closed)="onFilterClosed($event, filter)"
            selectClass="pointee-mat-select"
          ></shared-select-with-search>
        }
      </div>
    }
  </div>
}
