import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Chart, TooltipItem } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormatHelper } from '../../../helpers/format.helper';
import { TextHelper } from '../../../helpers/text.helper';
import { TextConstants } from '../../../text-constants';
import { ChartColor } from '../chart-color.enum';
import { tooltipTitleMultilinePlugin } from '../plugins/tooltip-title-multiline.plugin';
import { BarChartOrientation, BarChartConfiguration, BarChartMarkedValue, BarChartData, BarChartItem } from './bar-chart.type';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() data: BarChartData;
  @Input() configuration: BarChartConfiguration;
  @Input() markedValue: BarChartMarkedValue;
  @Input() selectedItemId: string;
  @Input() unnamedItemLabel = TextConstants.notAvailable;
  @Input() height = 'auto';
  @Input() annotations: AnnotationOptions[];

  @Output() readonly itemClick = new EventEmitter<BarChartItem>();

  get isHorizontal(): boolean {
    return this.configuration?.orientation !== BarChartOrientation.Vertical;
  }

  get isVertical(): boolean {
    return this.configuration?.orientation === BarChartOrientation.Vertical;
  }

  get maxBarThickness(): number {
    return this.configuration?.maxBarThickness ?? 24;
  }

  get fixedLabelWidth(): boolean {
    return this.configuration?.fixedLabelWidth ?? false;
  }

  get minYAxisWidth(): number {
    return this.configuration?.minYAxisWidth ?? 0;
  }

  datasetColors: string[] = [ChartColor.Blue, ChartColor.Expected, ChartColor.Gray40];
  private barChart: Chart;
  readonly fixedLabelWidthFactor = 0.5;

  @ViewChild('barChart', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  constructor(private cdt: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.initializeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuration?.isFirstChange() === false || changes.data?.isFirstChange() === false) {
      this.updateChart();
    }

    if (!changes.selectedItemId?.isFirstChange() === false) {
      this.updateBarColors(true);
    }
  }

  ngOnDestroy(): void {
    this.barChart?.destroy();
  }

  initializeChart(): void {
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.barChart = new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: [],
      },
      plugins: [ChartDataLabels, tooltipTitleMultilinePlugin],
      options: {
        indexAxis: this.isHorizontal ? 'y' : 'x',
        maintainAspectRatio: false,
        responsive: true,
        animation: {
          duration: 0,
        },
        interaction: {
          intersect: true,
        },
        scales: {
          y: {
            min: this.configuration.min,
            max: this.configuration.max,
            afterFit:
              this.isHorizontal && this.fixedLabelWidth
                ? scale => {
                    scale.width = scale.chart.width * this.fixedLabelWidthFactor;
                  }
                : this.isVertical && this.minYAxisWidth
                ? scale => {
                    scale.width = Math.max(this.minYAxisWidth ?? 0, scale.width);
                  }
                : undefined,
            grid: {
              drawTicks: this.isHorizontal && this.fixedLabelWidth,
              tickLength: this.isHorizontal && this.fixedLabelWidth ? 1000 : undefined,
            },
            border: {
              display: true,
              dash: [8, 4],
            },
            ticks: this.isHorizontal
              ? {
                  autoSkip: false,
                  crossAlign: 'far',
                  font: ctx => {
                    if (!this.data.items[ctx.index].label) {
                      return {
                        style: 'italic',
                        weight: 'bold',
                      };
                    }

                    return {
                      weight: this.data.items.findIndex(i => i.id === this.selectedItemId) === ctx.index ? 'bold' : 'normal',
                    };
                  },
                  color: ctx => {
                    return this.data.items[ctx.index].label ? ChartColor.Font : ChartColor.DarkRed;
                  },
                  callback: (value: string | number, index: number) => {
                    const label = this.data.items[index].label || this.unnamedItemLabel;
                    return TextHelper.textEllipsis(label, 100);
                  },
                }
              : {
                  autoSkip: false,
                  padding: 8,
                  callback: (value: string | number, index: number) => {
                    return FormatHelper.format(value, this.configuration.formatType);
                  },
                },
          },
          x: {
            grid: {
              display: false,
            },
            border: {
              display: true,
              color: ChartColor.Border,
            },
            ticks: {
              display: this.isHorizontal === false,
            },
          },
        },
        onClick: (event, elements, chart) => {
          if (elements.length === 0) {
            return;
          }

          const item = this.data.items[elements[0].index];
          this.itemClick.emit(item);
        },
        plugins: {
          legend: {
            display: this.configuration.showLegend ?? false,
            reverse: true,
            position: 'bottom',
            labels: {
              boxHeight: 12,
              boxWidth: 24,
              font: {
                size: 14,
              },
              useBorderRadius: true,
              borderRadius: 4,
            },
          },
          title: {
            display: this.configuration.title != null,
            align: 'start',
            color: ChartColor.FontTitle,
            padding: {
              bottom: 16,
            },
            font: {
              size: 16,
              lineHeight: '24px',
              weight: 500,
            },
          },
          tooltip: {
            mode: this.isHorizontal ? 'y' : 'index',
            caretPadding: 8,
            boxPadding: 4,
            itemSort: (a, b) => {
              return a.datasetIndex - b.datasetIndex;
            },
            footerMarginTop: 10,
            footerFont: {
              style: 'italic',
              weight: 'normal',
              size: 14,
            },
            footerAlign: 'center',
            callbacks: {
              label: (item: TooltipItem<'bar'>) => {
                const label = item.dataset.label ? `${item.dataset.label}: ` : '';

                if (item.datasetIndex === 0) {
                  const value = FormatHelper.format(this.data.items[item.dataIndex].value, this.configuration.formatType, false, true, this.configuration.unit);
                  return `${label}${value}`;
                }

                if (item.datasetIndex === 1 && this.data.items[item.dataIndex].lowLimit != null && this.data.items[item.dataIndex].highLimit == null) {
                  const lowLimit = FormatHelper.format(this.data.items[item.dataIndex].lowLimit, this.configuration.formatType, false, true);
                  return `${label}${lowLimit}`;
                }

                if (item.datasetIndex === 1 && this.data.items[item.dataIndex].lowLimit != null && this.data.items[item.dataIndex].highLimit != null) {
                  const lowLimit = FormatHelper.format(this.data.items[item.dataIndex].lowLimit, this.configuration.formatType, false, true);
                  const highLimit = FormatHelper.format(this.data.items[item.dataIndex].highLimit, this.configuration.formatType, false, true, this.configuration.unit);
                  return `${label}${lowLimit} - ${highLimit}`;
                }

                return null;
              },
              footer: this.configuration.tooltipFooter ? () => this.configuration.tooltipFooter : undefined,
            },
          },
          datalabels: {
            display: ctx => {
              return this.isHorizontal && ctx.datasetIndex === 0;
            },
            anchor: 'end',
            align: 'end',
            font: {
              size: 14,
              weight: 'bold',
            },
            formatter: (value: any) => {
              return FormatHelper.format(value, this.configuration.formatType, false, false, this.configuration.unit);
            },
          },
          annotation: {
            annotations: [],
          },
        },
      },
    });

    this.updateChart();
  }

  private updateChart(): void {
    this.cdt.markForCheck();
    if (this.barChart == null || this.configuration == null || this.data == null) {
      return;
    }

    if (this.isHorizontal) {
      const maxValue = this.configuration.max ?? Math.max(...this.data.items.map(i => i.value ?? 0));
      if (maxValue > 0) {
        this.barChart.options.scales.x.max = maxValue * 1.2;
      }
    }

    this.datasetColors = [];
    this.barChart.options.plugins.title.text = this.configuration.title;
    this.barChart.data.labels = this.data.items.map(i => i.label || this.unnamedItemLabel);
    this.barChart.data.datasets = [
      {
        data: this.data.items.map(i => i.value),
        label: this.configuration.dataLabels ? this.configuration.dataLabels[0] : '',
        maxBarThickness: this.maxBarThickness,
        minBarLength: 3,
        barPercentage: 0.75,
        borderWidth: 0,
        borderRadius: 4,
        order: 1,
      },
    ];
    this.datasetColors.push(this.configuration.color);
    if (this.data.items.some(i => i.lowLimit && !i.highLimit)) {
      this.barChart.data.datasets.push({
        type: 'line',
        data: this.data.items.map(i => i.lowLimit ?? 0),
        label: this.configuration.dataLabels[this.barChart.data.datasets.length],
        pointBackgroundColor: ChartColor.Expected,
        borderColor: ChartColor.Expected,
        backgroundColor: ChartColor.Expected,
        fill: false,
        order: 0,
        pointStyle: false,
      });
      this.datasetColors.push(ChartColor.Expected);
    }

    if (this.data.items.some(i => i.lowLimit && i.highLimit)) {
      this.barChart.data.datasets.push({
        data: this.data.items.map(i => [i.lowLimit ?? 0, i.highLimit ?? 0]),
        label: 'Expected ' + this.configuration.dataLabels[0],
        maxBarThickness: this.maxBarThickness,
        backgroundColor: ChartColor.LightGreen,
        borderColor: ChartColor.Transparent,
        borderWidth: {
          top: 4,
          bottom: 4,
        },
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
        grouped: false,
        order: 0,
      });
    }

    this.updateSelectedItem();
    this.refreshAnnotations();
    this.updateBarColors(false);

    if (this.height == 'auto') {
      let height = 1.25 * this.maxBarThickness * this.data.items.length + 20;
      if (this.configuration.title != null) {
        height += 40;
      }
      if (this.configuration.showLegend) {
        height += 36;
      }
      this.barChart.resize(this.barChart.width, height);
    }

    this.barChart.update();
  }

  private updateSelectedItem(): void {
    if (this.selectedItemId == null) {
      return;
    }

    if (this.data.items.some(i => i.id === this.selectedItemId)) {
      return;
    }

    this.selectedItemId = null;
  }

  private updateBarColors(shouldUpdateChart: boolean): void {
    if (this.barChart == null || this.configuration == null || this.data == null) {
      return;
    }

    const colors = new Array<string>(this.data.items.length).fill(null).map((_, index) => this.getBarColor(index));
    this.barChart.data.datasets[0].backgroundColor = colors;
    this.barChart.data.datasets[0].hoverBackgroundColor = colors;
    this.barChart.data.datasets[0].hoverBorderColor = colors;

    if (this.selectedItemId != null) {
      const selectedIndex = this.data.items.findIndex(i => i.id === this.selectedItemId);
      this.barChart.data.datasets[0].backgroundColor = colors.map((c, index) => (selectedIndex != index ? ChartColor.Gray40 : c));
    }

    if (shouldUpdateChart) {
      this.barChart.update();
    }
  }

  private getBarColor(index: number): string {
    return this.data.items[index].value >= this.configuration.threshold ? this.configuration.colorAboveThreshold : this.configuration.color;
  }

  private refreshAnnotations(): void {
    if (this.barChart?.options?.plugins?.annotation == null) {
      return;
    }

    const annotations = this.annotations ?? [];
    const markedValueAnnotations = this.getAnnotations();
    this.barChart.options.plugins.annotation.annotations = annotations.concat(markedValueAnnotations);
  }

  private getAnnotations(): AnnotationOptions[] {
    if (this.markedValue == null) {
      return [];
    }

    const valueLabel = this.markedValue.label ? `${this.markedValue.label}: ` : '';
    return [
      {
        type: 'line',
        xMin: this.isHorizontal ? this.markedValue.value : null,
        xMax: this.isHorizontal ? this.markedValue.value : null,
        yMin: this.isHorizontal ? null : this.markedValue.value,
        yMax: this.isHorizontal ? null : this.markedValue.value,
        borderColor: this.markedValue.color,
        borderDash: [5, 5],
        borderWidth: 2,
        drawTime: 'afterDraw',
        label: {
          display: false,
          content: `${valueLabel}${FormatHelper.format(this.markedValue.value, this.configuration.formatType, false, true, this.configuration.unit)}`,
          position: 'center',
          padding: {
            x: 8,
            y: 4,
          },
          backgroundColor: this.markedValue.hoverColor ?? this.markedValue.color,
        },
        enter: ({ element, chart }) => {
          chart.canvas.style.cursor = 'pointer';
          element.options.borderDash = [];
          element.options.borderColor = this.markedValue.hoverColor ?? this.markedValue.color;
          element.label.options.display = true;
          element.label.options.z = 1; // to be on top of all other annotations
          return true;
        },
        leave: ({ element, chart }) => {
          chart.canvas.style.cursor = 'default';
          element.options.borderDash = [5, 5];
          element.options.borderColor = this.markedValue.color;
          element.label.options.display = false;
          element.label.options.z = 0;
          return true;
        },
      },
    ];
  }
}
