<div class="form-control chip-input" [class.disabled]="disabled">
  @for (value of values; track value; let i = $index) {
    <div class="chip">
      <span class="chip-content">{{ value }}</span>
      <button type="button" class="btn btn-icon-only" (click)="removeValue($event, i)">
        <svg-icon key="close" size="xs"></svg-icon>
      </button>
    </div>
  }
  <input
    #chipInput
    class="form-control w-auto"
    [placeholder]="maxCount && maxCount <= values.length ? '' : placeholder"
    (keydown)="onKeyDown($event)"
    (keydown.enter)="autoselectKeyDown($event)"
    autocomplete="off"
    [disabled]="disabled"
    />
  </div>
  <!--  DropDown Starts  -->
  <div class="card autocomplete-search" [hidden]="!showSearches" (keydown.enter)="autoselectKeyDown($event)">
    @for (item of autocompleteValues; track $index; let i = $index) {
      <div (click)="setItem(item)" [class.active]="i === activeIndex" class="search-result">
        {{ item }}
      </div>
    }
  </div>
  <!--  DropDown Ends  -->
