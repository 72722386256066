import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ProcessQueueDefinition } from 'src/app/shared/types/process-group-definition.type';
import { Platform } from '../../enums/generated.enums';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { DateHelper } from '../../helpers/date.helper';
import { toBase64 } from '../../helpers/encoding.helper';
import { TextHelper } from '../../helpers/text.helper';
import { ActionResult } from '../../types/action-result.type';
import { ActionableInsight, UserActionableInsight } from '../../types/actionable-insight.type';
import { ApplicationConfiguration } from '../../types/application-configuration.type';
import { BaseEntity } from '../../types/base-entity.type';
import { BucketedUtilization } from '../../types/bucketed-utilization.type';
import { License } from '../../types/license.type';
import { LicensesOfRunningProcesses } from '../../types/licenses-of-running-processes.type';
import { LicensesUtilization } from '../../types/licenses-utilization.type';
import { MasterProcessUserData } from '../../types/master-process-user-data.type';
import { MasterProcess } from '../../types/master-process.type';
import { Organization, OrganizationConfiguration } from '../../types/organization.type';
import { ProcessBasicInfo } from '../../types/process-basic-Info.type';
import { Process } from '../../types/process.type';
import { ResourceList } from '../../types/resource-list.type';
import { ResourcesOfRunningProcesses } from '../../types/resources-of-running-processes.type';
import { ResourcesUtilization } from '../../types/resources-utilization.type';
import { Schedule } from '../../types/schedule.type';
import { SchedulerMissingItem } from '../../types/scheduler/scheduler-missing-item.type';
import { ItemsStatistics } from '../../types/statistics/items-statistics.type';
import { User } from '../../types/user.type';
import { AlertRulesApiService } from './alert-rules-api.service';
import { BaseApiService } from './base-api.service';
import { BenchmarkMetricsApiService } from './benchmark-metrics-api.service';
import { BillingGroupsApiService } from './billing-groups-api.service';
import { DomainsApiService } from './domains-api.service';
import { EmulatorMonitoringApiService } from './emulator-monitoring-api.service';
import { EmulatorTasksApiService } from './emulator-tasks-api.service';
import { IncidentsApiService } from './incidents-api.service';
import { ManagementMetricsApiService } from './management-metrics-api.service';
import { MetricPredictionsApiService } from './metric-predictions-api.service';
import { MetricsApiService } from './metrics-api.service';
import { MonitoringApiService } from './monitoring-api.service';
import { NotificationsApiService } from './notifications-api.service';
import { OrganizationSettingsApiService } from './organization-settings-api.service';
import { ProcessGroupsApiService } from './process-groups-api.service';
import { ProcessVersionsApiService } from './process-versions-api.service';
import { ReportsApiService } from './reports-api.service';
import { ResourceGroupsApiService } from './resource-groups-api.service';
import { ResourcesApiService } from './resources-api.service';
import { SchedulerApiService } from './scheduler-api.service';
import { SchedulerConditionStatesApiService } from './scheduler-condition-states-api.service';
import { SchedulerDumpApiService } from './scheduler-dump-api.service';
import { TasksApiService } from './tasks-api.service';
import { UserConfigurationApiService } from './user-configuration-api.service';
import { Versions } from '../../types/versions.type';
import { ImportApiService } from './import-api.service';
import { CalendarApiService } from './calendar-api.service';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseApiService {
  constructor(
    httpClient: HttpClient,
    public alertRules: AlertRulesApiService,
    public benchmarkMetrics: BenchmarkMetricsApiService,
    public billingGroups: BillingGroupsApiService,
    public calendar: CalendarApiService,
    public domains: DomainsApiService,
    public emulatorMonitoring: EmulatorMonitoringApiService,
    public emulatorTasks: EmulatorTasksApiService,
    public importApi: ImportApiService,
    public incidents: IncidentsApiService,
    public managementMetrics: ManagementMetricsApiService,
    public metrics: MetricsApiService,
    public monitoring: MonitoringApiService,
    public notifications: NotificationsApiService,
    public organizationSettings: OrganizationSettingsApiService,
    public predictions: MetricPredictionsApiService,
    public processVersions: ProcessVersionsApiService,
    public processGroups: ProcessGroupsApiService,
    public reports: ReportsApiService,
    public resourceGroups: ResourceGroupsApiService,
    public resources: ResourcesApiService,
    public scheduler: SchedulerApiService,
    public schedulerConditionStates: SchedulerConditionStatesApiService,
    public schedulerDump: SchedulerDumpApiService,
    public tasks: TasksApiService,
    public userConfiguration: UserConfigurationApiService
  ) {
    super(httpClient);
  }

  async getVersion(): Promise<Versions> {
    return await this.get<Versions>('version');
  }

  async getDocumentationUrl(): Promise<string> {
    return await this.get<string>('documentation-url');
  }

  async getApplicationConfiguration(): Promise<ApplicationConfiguration> {
    return await this.get<ApplicationConfiguration>('app-configuration');
  }

  async getTimeZones(): Promise<BaseEntity[]> {
    return await this.get<BaseEntity[]>('time-zones');
  }

  //LOGIN
  async login(email: string, password: string): Promise<void> {
    await this.post<{ email: string; password: string }>('login', { email, password });
  }

  async loginWithMicrosoft(token: string, organizationName?: string): Promise<void> {
    await this.post('login/microsoft', { token, organizationName });
  }

  async refreshToken(): Promise<void> {
    await this.get<void>('login/refresh-token');
  }

  async logout(): Promise<void> {
    await this.get<void>('login/logout');
  }

  evaluatePassword(password: string): Promise<{ suggestions: string[]; warning: string; score: number }> {
    return this.postWithResult<{ password: string }, { suggestions: string[]; warning: string; score: number }>('login/evaluate-password', { password });
  }

  async getOrganizationNames(): Promise<{ name: string; label: string }[]> {
    return await this.get<{ name: string; label: string }[]>('login/organization-names');
  }

  async getOrganizationConfiguration(): Promise<OrganizationConfiguration> {
    const result = await this.get<OrganizationConfiguration>('login/organization-configuration');
    ApiConversionHelper.convertTimeSpans(result, ['businessHoursFrom', 'businessHoursTo']);
    return result;
  }

  async setOrganization(organizationName: string): Promise<void> {
    await this.post<{ organizationName: string }>('login/organization', { organizationName });
  }

  async uploadOrganizationLogo(file: File, organizationName: string): Promise<void> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const url = `${this.getUrl('organizations')}/${organizationName}/logo`;
    const options = { headers: { 'Content-Type': 'multipart/form-data' } };
    await firstValueFrom(this.httpClient.post(url, formData, options));
  }

  getOrganizationLogo(organizationName: string): Promise<Blob> {
    return this.getBlob(`organizations/${organizationName}/logo`);
  }

  deleteOrganizationLogo(organizationName: string): Promise<void> {
    return this.delete(`organizations/${organizationName}/logo`);
  }

  async resetPassword(email: string): Promise<string> {
    return await this.postWithResult('login/password-reset', { email });
  }

  async checkPasswordResetToken(token: string): Promise<string> {
    const response = await this.get<{ firstName: string }>(`login/password/${token}`);
    return response && response.firstName;
  }

  async setPassword(token: string, currentPassword: string | null, newPassword: string): Promise<void> {
    await this.put('login/password', { token, currentPassword, newPassword });
  }

  //PROCESSES
  async getMasterProcesses(monitoringProcessGroupId?: string): Promise<ProcessBasicInfo[]> {
    const result = await this.get<ProcessBasicInfo[]>(`master-processes/${monitoringProcessGroupId ?? ''}`);
    result.forEach(p => ApiConversionHelper.convertProcessBasicInfo(p));
    return result;
  }

  async getMasterProcessReport(masterProcessId: string): Promise<MasterProcess> {
    const result = await this.get<MasterProcess>(`master-processes/${masterProcessId}/report`);
    ApiConversionHelper.convertMasterProcess(result);
    return Object.assign(new MasterProcess(), result);
  }

  async getMasterProcessUserData(processId: string): Promise<MasterProcessUserData> {
    const result = await this.get<MasterProcessUserData>(`master-processes/${processId}/user-data`);
    if (result) {
      ApiConversionHelper.convertMasterProcessUserData(result);
    }
    return result;
  }

  async getProcessReport(processId: string): Promise<Process> {
    const result = await this.get<Process>(`processes/${processId}/report`);
    ApiConversionHelper.convertProcess(result);
    return result;
  }

  addMasterProcess(file: File, platform: Platform, monitoringProcessGroupId?: string): Observable<HttpEvent<{ processLabel: string; masterProcessId: string }>> {
    return this.httpClient.post<{ processLabel: string; masterProcessId: string }>(this.getUrl('master-processes'), file, {
      reportProgress: true,
      observe: 'events',
      headers: { FileName: TextHelper.removeDiacritics(file.name), Platform: platform, MonitoringProcessGroupId: monitoringProcessGroupId ?? '' },
    });
  }

  async cancelMasterProcess(masterProcessId: string): Promise<void> {
    return await this.get(`master-processes/${masterProcessId}/cancel`);
  }

  async deleteMasterProcess(masterProcessId: string): Promise<void> {
    return await this.delete(`master-processes/${masterProcessId}`);
  }

  async updateEstimationInterval(masterProcessId: string, masterProcessUserData: MasterProcessUserData): Promise<void> {
    return await this.patch(`master-processes/${masterProcessId}/estimation-interval`, {
      calculatedIntervalCasesCount: masterProcessUserData.calculatedIntervalCasesCount,
      enteredIntervalCasesCount: masterProcessUserData.enteredIntervalCasesCount,
      intervalTypeForCasesCounts: masterProcessUserData.intervalTypeForCasesCounts,
    });
  }

  async updateManualProcessingCosts(masterProcessId: string, manualProcessingCosts?: number): Promise<void> {
    return await this.patch(`master-processes/${masterProcessId}/manual-processing-costs`, {
      manualProcessingCosts,
    });
  }

  async updateActionableInsight(masterProcessId: string, actionableInsight: ActionableInsight): Promise<void> {
    return await this.patch(`master-processes/${masterProcessId}/actionable-insights/${actionableInsight.id}`, {
      states: [...actionableInsight.states],
    });
  }

  async addUserActionableInsight(masterProcessId: string, userActionableInsight: UserActionableInsight): Promise<void> {
    return await this.post(`master-processes/${masterProcessId}/user-actionable-insights`, userActionableInsight);
  }

  async updateUserActionableInsight(masterProcessId: string, userActionableInsight: UserActionableInsight): Promise<void> {
    userActionableInsight.savedItemMeanDuration = DateHelper.formatDuration(userActionableInsight.savedItemMeanDuration);
    return await this.patch(`master-processes/${masterProcessId}/user-actionable-insights`, userActionableInsight);
  }

  async deleteUserActionableInsight(masterProcessId: string, actionableInsightId: string): Promise<void> {
    return await this.delete(`master-processes/${masterProcessId}/user-actionable-insights/${actionableInsightId}`);
  }

  async getItemsStatistics(processId: string, variantIds: string[]): Promise<ItemsStatistics> {
    const body = { variantIds };
    const result = await this.postWithResult<{ variantIds: string[] }, ItemsStatistics>(`processes/${processId}/items-statistics`, body);
    ApiConversionHelper.convertItemsStatistics(result);
    return result;
  }

  //DOWNLOAD
  downloadMissingLines(masterProcessId: string): void {
    this.download(`download/missing-lines/${masterProcessId}`);
  }

  //MONITORING CONFIGURATION
  async updateMonitoringRPAProcesses(process: ProcessQueueDefinition): Promise<void> {
    return await this.put('monitoring-configuration/processes', process);
  }

  async getMonitoringUnassignedProcesses(): Promise<ProcessQueueDefinition[]> {
    return await this.get<ProcessQueueDefinition[]>('monitoring-configuration/unassigned-processes');
  }

  async getMonitoringUnassignedQueues(): Promise<ProcessQueueDefinition[]> {
    return await this.get<ProcessQueueDefinition[]>('monitoring-configuration/unassigned-queues');
  }

  async updateMonitoringQueues(queue: ProcessQueueDefinition): Promise<void> {
    return await this.put('monitoring-configuration/queues', queue);
  }

  //UTILIZATION
  async getLicensesUtilization(date: Date): Promise<LicensesUtilization> {
    const result = await this.get<LicensesUtilization>(`utilization/licenses/${DateHelper.formatApiDateOnly(date)}`);
    ApiConversionHelper.convertUtilization(result);
    return result;
  }

  async getBucketedLicensesUtilization(from: Date, to: Date): Promise<BucketedUtilization> {
    const result = await this.get<BucketedUtilization>(`utilization/licenses-bucketed/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`);
    ApiConversionHelper.convertBucketedUtilization(result);
    return result;
  }

  async getResources(from: Date, to: Date): Promise<ResourceList> {
    return await this.get<ResourceList>(`utilization/resources/list/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`);
  }

  async getResourcesUtilization(from: Date, to: Date, resourceId?: string): Promise<ResourcesUtilization> {
    const result = await this.get<ResourcesUtilization>(`utilization/resources/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}/${resourceId ?? ''}`);
    ApiConversionHelper.convertUtilization(result);
    return result;
  }

  async getBucketedResourcesUtilization(from: Date, to: Date, resourceId?: string): Promise<BucketedUtilization> {
    const result = await this.get<BucketedUtilization>(`utilization/resources-bucketed/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}/${resourceId ?? ''}`);
    ApiConversionHelper.convertBucketedUtilization(result);
    return result;
  }

  async getLicensesOfRunningProcesses(timeStamp: Date): Promise<LicensesOfRunningProcesses> {
    return await this.get<LicensesOfRunningProcesses>(`utilization/licenses/processes/${DateHelper.formatApiDateTime(timeStamp)}`);
  }

  async getResourcesOfRunningProcesses(timeStamp: Date, resourceId: string): Promise<ResourcesOfRunningProcesses> {
    return await this.get<ResourcesOfRunningProcesses>(`utilization/resources/processes/${DateHelper.formatApiDateTime(timeStamp)}/${resourceId ?? ''}`);
  }

  async getProcessScheduleResources(date: Date): Promise<Schedule> {
    const result = await this.get<Schedule>(`utilization/resources/schedule/resources/${DateHelper.formatApiDateOnly(date)}`);
    ApiConversionHelper.convertSchedule(result);
    return result;
  }

  async getProcessScheduleUsers(date: Date): Promise<Schedule> {
    const result = await this.get<Schedule>(`utilization/resources/schedule/users/${DateHelper.formatApiDateOnly(date)}`);
    ApiConversionHelper.convertSchedule(result);
    return result;
  }

  //ORGANIZATION
  async getOrganizations(): Promise<Organization[]> {
    const result = await this.get<Organization[]>('organizations');
    result.forEach(o => ApiConversionHelper.convertTimeSpans(o.configuration, ['businessHoursFrom', 'businessHoursTo']));
    return result;
  }

  async createOrganization(organization: Organization): Promise<void> {
    ApiConversionHelper.convertTimeSpansForApi(organization.configuration, ['businessHoursFrom', 'businessHoursTo']);
    await this.post('organizations', organization);
  }

  async updateOrganization(organization: Organization): Promise<void> {
    ApiConversionHelper.convertTimeSpansForApi(organization.configuration, ['businessHoursFrom', 'businessHoursTo']);
    await this.put('organizations', organization);
  }

  async requestOrganizationHistoryData(organizationName: string): Promise<void> {
    await this.get(`organizations/${organizationName}/request-history-data`);
  }

  async generateOrganizationMonitoringDemoData(organizationName: string): Promise<ActionResult> {
    return await this.postWithResult(`organizations/${organizationName}/actions/generate-monitoring-data`, null);
  }

  async deleteAllOrganizationData(organizationName: string, retypeOrganizationName: string): Promise<ActionResult> {
    return await this.postWithResult(`organizations/${organizationName}/actions/delete-all-data?key=${retypeOrganizationName}`, null);
  }

  async generateDemoData(): Promise<void> {
    await this.post('organizations/generate-demo-data', null);
  }

  //USER
  async getUsers(): Promise<User[]> {
    const result = await this.get<User[]>('users');
    ApiConversionHelper.convertDates(result, ['lastLogin']);
    return result;
  }

  async createUser(user: User): Promise<string> {
    return await this.postWithResult('users', user);
  }

  async updateUser(user: User): Promise<void> {
    return await this.put(`users/${user.id}`, user);
  }

  async deleteUser(user: User): Promise<void> {
    return await this.delete(`users/${user.id}`);
  }

  //LICENSE
  async getLicenses(): Promise<License[]> {
    return await this.get<License[]>('licenses');
  }

  async createLicense(license: License): Promise<void> {
    return await this.post('licenses', license);
  }

  downloadLicenseKey(licenseId: string): void {
    this.download(`licenses/download/${licenseId}`);
  }

  //CUSTOMER FEEDBACK
  async sendCustomerFeedbackMessage(message: string, attachments?: string[]): Promise<void> {
    await this.postWithResult('feedback/message', { message, attachments });
  }

  uploadCustomerFile(file: File): Observable<HttpEvent<{ fileId: string }>> {
    return this.httpClient.post<{ fileId: string }>(this.getUrl('feedback/file'), file, {
      headers: { FileName: TextHelper.removeDiacritics(file.name) },
      reportProgress: true,
      observe: 'events',
    });
  }

  deleteCustomerFile(fileId: string): Promise<void> {
    const encodedFileId = toBase64(fileId);
    return this.delete(`feedback/file/${encodedFileId}`);
  }

  //SCHEDULER EMULATOR
  async runEmulator(from: Date, to: Date): Promise<void> {
    await this.get<void>(`scheduler-emulator/run-emulator/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`);
  }

  async getSchedulerEmulatorLicensesUtilization(date: Date): Promise<LicensesUtilization> {
    const result = await this.get<LicensesUtilization>(`scheduler-emulator/licenses/${DateHelper.formatApiDateOnly(date)}`);
    ApiConversionHelper.convertUtilization(result);
    return result;
  }

  async getSchedulerEmulatorBucketedLicensesUtilization(from: Date, to: Date): Promise<BucketedUtilization> {
    const result = await this.get<BucketedUtilization>(`scheduler-emulator/licenses-bucketed/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`);
    ApiConversionHelper.convertBucketedUtilization(result);
    return result;
  }

  async getSchedulerEmulatorResources(from: Date, to: Date): Promise<ResourceList> {
    return await this.get<ResourceList>(`scheduler-emulator/resources/list/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`);
  }

  async getSchedulerEmulatorResourcesUtilization(from: Date, to: Date, resourceId?: string): Promise<ResourcesUtilization> {
    const result = await this.get<ResourcesUtilization>(`scheduler-emulator/resources/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}/${resourceId ?? ''}`);
    ApiConversionHelper.convertUtilization(result);
    return result;
  }

  async getSchedulerEmulatorBucketedResourcesUtilization(from: Date, to: Date, resourceId?: string): Promise<BucketedUtilization> {
    const result = await this.get<BucketedUtilization>(`scheduler-emulator/resources-bucketed/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}/${resourceId ?? ''}`);
    ApiConversionHelper.convertBucketedUtilization(result);
    return result;
  }

  async getSchedulerEmulatorLicensesOfRunningProcesses(timeStamp: Date): Promise<LicensesOfRunningProcesses> {
    return await this.get<LicensesOfRunningProcesses>(`scheduler-emulator/licenses/processes/${DateHelper.formatApiDateOnly(timeStamp)}`);
  }

  async getSchedulerEmulatorResourcesOfRunningProcesses(timeStamp: Date, resourceId: string): Promise<ResourcesOfRunningProcesses> {
    return await this.get<ResourcesOfRunningProcesses>(`scheduler-emulator/resources/processes/${DateHelper.formatApiDateOnly(timeStamp)}/${resourceId ?? ''}`);
  }

  async getSchedulerEmulatorProcessScheduleResources(date: Date): Promise<Schedule> {
    const result = await this.get<Schedule>(`scheduler-emulator/resources/schedule/resources/${DateHelper.formatApiDateOnly(date)}`);
    ApiConversionHelper.convertSchedule(result);
    return result;
  }

  async getSchedulerEmulatorProcessScheduleUsers(date: Date): Promise<Schedule> {
    const result = await this.get<Schedule>(`scheduler-emulator/resources/schedule/users/${DateHelper.formatApiDateOnly(date)}`);
    ApiConversionHelper.convertSchedule(result);
    return result;
  }

  async getSchedulerEmulatorProcessScheduleResourcesMissing(date: Date): Promise<Schedule> {
    const result = await this.get<Schedule>(`scheduler-emulator/missing-sessions/resources/schedule/resources/${DateHelper.formatApiDateOnly(date)}`);
    ApiConversionHelper.convertSchedule(result);
    return result;
  }

  async getSchedulerEmulatorProcessScheduleUsersMissing(date: Date): Promise<Schedule> {
    const result = await this.get<Schedule>(`scheduler-emulator/missing-sessions/resources/schedule/users/${DateHelper.formatApiDateOnly(date)}`);
    ApiConversionHelper.convertSchedule(result);
    return result;
  }

  async getSchedulerEmulatorMissingItems(date: Date): Promise<SchedulerMissingItem[]> {
    const result = await this.get<SchedulerMissingItem[]>(`scheduler-emulator/missing-items/${DateHelper.formatApiDateOnly(date)}`);
    return result;
  }

  async getSchedulerLicenseUtilization(date: Date): Promise<Schedule> {
    const result = await this.get<Schedule>(`scheduler-emulator/licenses/schedule/utilization/${DateHelper.formatApiDateOnly(date)}`);
    ApiConversionHelper.convertSchedule(result);
    return result;
  }

  // WEBHOOKS
  async getUiPathEventsUrl(): Promise<string> {
    return await this.get<string>('webhooks/uipath/event-url');
  }

  async getSchedulerWebhookUrlPrefix(): Promise<string> {
    return await this.get<string>('webhooks/orchestrator-url-prefix');
  }
}
