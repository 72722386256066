import { Injectable } from '@angular/core';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { DateHelper } from '../../helpers/date.helper';
import { ProcessGroupDefinition } from '../../types/process-group-definition.type';
import { ProcessGroupDetail } from '../../types/process-group-detail.type';
import { ProcessGroupListing } from '../../types/process-group-listing.type';
import { ProcessGroupOverview } from '../../types/process-group-overview.type';
import { ProcessGroupVisibility } from '../../types/process-group-visibility.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class ProcessGroupsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/process-groups`;
  }

  async getProcessGroups(): Promise<ProcessGroupDefinition[]> {
    return await this.get<ProcessGroupDefinition[]>('');
  }

  async getProcessGroupsListing(from?: Date, to?: Date): Promise<ProcessGroupListing[]> {
    return from && to ? await this.get<ProcessGroupListing[]>(`list/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`) : await this.get<ProcessGroupListing[]>('list');
  }

  async createProcessGroup(processGroup: ProcessGroupDefinition): Promise<string> {
    return await this.postWithResult<ProcessGroupDefinition, string>('', processGroup);
  }

  async updateProcessGroup(processGroup: ProcessGroupDefinition): Promise<void> {
    return await this.put('', processGroup);
  }

  async deleteProcessGroup(processGroupId: string): Promise<void> {
    return await this.delete(`${processGroupId}/true`);
  }

  async getProcessGroupOverviews(from: Date, to: Date, domainId?: string): Promise<ProcessGroupOverview[]> {
    const result = await this.get<ProcessGroupOverview[]>(`${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}/${domainId ?? ''}`);
    result.forEach(d => ApiConversionHelper.convertMonitoringProcessGroupOverview(d));
    return result;
  }

  async getProcessGroupDetail(from: Date, to: Date, processGroupId: string): Promise<ProcessGroupDetail> {
    const result = await this.get<ProcessGroupDetail>(`${processGroupId}/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`);
    ApiConversionHelper.convertMonitoringProcessGroupDetail(result);
    return result;
  }

  async getProcessGroupsVisibility(): Promise<ProcessGroupVisibility[]> {
    return await this.get<ProcessGroupVisibility[]>('visibility');
  }

  async updateProcessGroupsVisibility(processGroupsToHide: string[], processGroupsToShow: string[]): Promise<void> {
    return await this.patch('visibility', { processGroupsToHide, processGroupsToShow });
  }
}
