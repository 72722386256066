import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { FlameGraphNode } from 'src/app/shared/types/flame-graph-data.type';
import { ActionableInsightStatus, ActionableInsightType, Platform } from '../enums/generated.enums';
import { CurrencyHelper } from '../helpers/currency.helper';
import { DateHelper } from '../helpers/date.helper';
import { MapHelper } from '../helpers/map.helper';
import { ApiService } from '../services/api/api.service';
import { GraphvizService } from '../services/graphviz.service';
import { LoggingService } from '../services/logging.service';
import { StorageService } from '../services/storage.service';
import { ActionableInsight } from './actionable-insight.type';
import { Edge } from './edge.type';
import { MasterProcessUserData } from './master-process-user-data.type';
import { MasterProcess } from './master-process.type';
import { Node } from './node.type';
import { OrganizationConfiguration } from './organization.type';
import { EdgePosition, Positions } from './positions.type';
import { ProcessBasicInfo } from './process-basic-Info.type';
import { Process } from './process.type';
import { EdgeStatistics } from './statistics/edge-statistics.type';
import { ProcessStatistics } from './statistics/process-statistics.type';
import { SuccessRate } from './success-rate.type';
import { VariantTree } from './variant-tree.type';
import { Variant } from './variant.type';

@Injectable()
export class Data {
  reloading = false;
  canNotBeLoaded: boolean;
  masterProcessLoaded = false;
  processLoaded = false;
  masterProcessId?: string;
  masterProcess?: MasterProcess;
  masterProcessUserData?: MasterProcessUserData;
  process?: Process;
  positions: Positions;
  nodes: Map<number, Node> | null;
  realNodes: Map<number, Node>;
  selectedNodes: Node[];
  rootNodes: Node[];
  edges: Edge[];
  realEdges: Edge[];
  virtualEdges: Edge[];
  selectedEdges: Edge[];
  variants: Map<string, Variant>;
  variantsTree: Map<string, VariantTree> = null;
  selectedVariants: Variant[];
  masterProcessUserDataChanged = new ReplaySubject<MasterProcessUserData>();
  actionableInsightsByStateChanged = new ReplaySubject<void>();
  organizationConfiguration: OrganizationConfiguration;

  get loaded(): boolean {
    return this.masterProcessLoaded && this.processLoaded;
  }

  set loaded(loaded: boolean) {
    this.masterProcessLoaded = loaded;
    this.processLoaded = loaded;
  }

  get platform(): Platform {
    return this.masterProcess?.platform ?? this.organizationConfiguration?.platforms[0] ?? Platform.BluePrism;
  }

  hasPlatform(platform: Platform): boolean {
    return this.organizationConfiguration?.platforms?.indexOf(platform) >= 0;
  }

  get isUiPath(): boolean {
    return (this.platform === Platform.UiPath) || false;
  }

  get isBluePrism(): boolean {
    return (this.platform === Platform.BluePrism) || false;
  }

  constructor(protected graphvizService: GraphvizService, private apiService: ApiService, private loggingService: LoggingService, private storageService: StorageService) {}

  async initializeMasterProcess(masterProcessId: string): Promise<void> {
    this.masterProcessLoaded = false;
    this.masterProcessId = masterProcessId;
    try {
      this.masterProcess = await this.apiService.getMasterProcessReport(masterProcessId);
      this.calculateSuccessRate(this.masterProcess.itemsProcessStatistics);
      this.calculateSuccessRate(this.masterProcess.sessionsProcessStatistics);
      await this.initializeMasterProcessUserData(masterProcessId);
    } catch (error) {
      this.loggingService.logException(error as Error);
      this.canNotBeLoaded = true;
    } finally {
      this.masterProcessLoaded = true;
    }
  }

  async initializeProcess(processBasicInfo: ProcessBasicInfo): Promise<void> {
    if (!processBasicInfo) {
      return;
    }
    this.processLoaded = false;
    this.reloading = true;

    try {
      this.process = await this.apiService.getProcessReport(processBasicInfo.processId);
      this.process.index = processBasicInfo.index;
      CurrencyHelper.setCurrencySymbol(this.process.currencySymbol || this.organizationConfiguration.currencySymbol || CurrencyHelper.defaultCurrencySymbol);
      this.calculateSuccessRate(this.process.statistics);
      this.initializeProcessMapData();
      this.buildVariantsTree();
      this.canNotBeLoaded = false;
    } catch (error: any) {
      this.loggingService.logException(error);
      this.canNotBeLoaded = true;
      this.process = null;
    } finally {
      this.processLoaded = true;
      this.reloading = false;
    }
  }

  private calculateSuccessRate(statistics: ProcessStatistics) {
    statistics.successRate = new SuccessRate(
      this.organizationConfiguration.isBusinessExceptionSuccess,
      statistics.businessExceptionsCount,
      statistics.systemExceptionsCount,
      null,
      statistics.casesCount,
    );
  }

  public async initializeMasterProcessUserData(masterProcessId: string): Promise<void> {
    const userData = (await this.apiService.getMasterProcessUserData(masterProcessId)) ?? new MasterProcessUserData();
    this.addUserData(userData);
    this.initializeActionableInsights();
    this.masterProcessUserData = userData;
    this.initializeUserActionableInsightsForFlameGraphNodes();
    this.initializeUserActionableInsightsForProcess();
    this.masterProcessUserDataChanged.next(userData);
  }

  private addUserData(userData: MasterProcessUserData) {
    const expanded = new Set(this.masterProcess.actionableInsights.filter(a => a.expanded && a.type === ActionableInsightType.User).map(a => a.id));
    this.masterProcess.actionableInsights = this.masterProcess.actionableInsights?.filter(a => a.type !== ActionableInsightType.User) ?? [];
    userData.userActionableInsights?.forEach(u => {
      u.expanded = expanded.has(u.id);
      this.masterProcess.actionableInsights.push(u);
    });
    this.masterProcess.actionableInsights = this.masterProcess.actionableInsights.sort((a, b) => b.savedCosts - a.savedCosts);

    const actionableInsightsUserData = userData?.actionableInsightsUserData ?? {};
    this.masterProcess.actionableInsights.forEach(a => {
      const states = actionableInsightsUserData[a.id]?.states;
      a.states = new Set(states);
    });
  }

  private initializeActionableInsights() {
    const aiMap = new Map<ActionableInsightStatus | '', Set<ActionableInsight>>();
    aiMap.set(
      '',
      this.getActionableInsights(a => !a.states.has(ActionableInsightStatus.Irrelevant)),
    );
    aiMap.set(
      ActionableInsightStatus.Starred,
      this.getActionableInsights(a => a.states.has(ActionableInsightStatus.Starred)),
    );
    aiMap.set(
      ActionableInsightStatus.Resolved,
      this.getActionableInsights(a => a.states.has(ActionableInsightStatus.Resolved)),
    );
    aiMap.set(
      ActionableInsightStatus.Irrelevant,
      this.getActionableInsights(a => a.states.has(ActionableInsightStatus.Irrelevant)),
    );
    if (this.masterProcess) {
      this.masterProcess.actionableInsightsByState = aiMap;
    }
    this.actionableInsightsByStateChanged.next();
  }

  initializeUserActionableInsightsForFlameGraphNodes() {
    const userActionableInsights = MapHelper.toDictionary(this.masterProcessUserData?.userActionableInsights, u => `${u.location.processId}-${u.location.itemId}`);

    const initializeNode: (n: FlameGraphNode) => void = n => {
      n.userActionableInsight = userActionableInsights.get(`${n.processId}-${n.itemId}`);
      n.children?.forEach(c => initializeNode(c));
    };

    initializeNode(this.masterProcess.flameGraphRoot);
  }

  private getActionableInsights(predicate: (a: ActionableInsight) => boolean) {
    return new Set(this.masterProcess?.actionableInsights.filter(predicate));
  }

  private initializeProcessMapData(): void {
    if (!this.process) {
      return;
    }

    this.edges = this.process.edges.map(e => this.toObject(e, Edge)) ?? [];
    this.nodes = MapHelper.toDictionary(
      this.process.nodes.map(n => this.toObject(n, Node)),
      n => n.id,
    );
    this.variants = MapHelper.toDictionary(
      Object.keys(this.process.variants)
        .map(k => this.process.variants[k])
        .map(v => this.toObject(v, Variant)),
      v2 => v2.id,
    );

    this.initializeUserActionableInsightsForProcess();
    this.linkObjects();
    const expandedNodeIds = new Set(this.storageService.expandedNodeIds);
    this.nodes.forEach(n => (n.isExpanded = expandedNodeIds.has(n.id)));
    this.refreshVisibility();
  }

  private toObject<T>(jsonObject: T, type: new () => T): T {
    const emptyObject = new type();
    Object.assign(emptyObject, jsonObject);
    return emptyObject;
  }

  private initializeUserActionableInsightsForProcess() {
    if (!this.process) {
      return;
    }
    const userActionableInsights = MapHelper.toDictionary(
      this.masterProcessUserData?.userActionableInsights?.filter(u => u.location.processId == this.process.id),
      u => u.location.itemId,
    );
    this.nodes.forEach(n => (n.userActionableInsight = userActionableInsights.get(n.itemId)));
    this.edges.forEach(e => (e.userActionableInsight = userActionableInsights.get(e.itemId)));
  }

  private linkObjects() {
    this.realNodes = MapHelper.toDictionary(
      MapHelper.filter(this.nodes, n => !n.isStartEnd),
      n => n.id,
    );

    this.rootNodes = [];
    this.nodes.forEach(n => {
      n.incomingEdges = new Map<number, Edge>();
      n.outgoingEdges = new Map<number, Edge>();
      if (n.parentNodeId != null) {
        this.nodes.get(n.parentNodeId).children.push(n);
      } else {
        this.rootNodes.push(n);
      }
    });
    if (this.edges) {
      this.edges.forEach(e => {
        e.startNode = this.nodes.get(e.from);
        e.endNode = this.nodes.get(e.to);
        e.startNode.outgoingEdges.set(e.to, e);
        e.endNode.incomingEdges.set(e.from, e);
        e.isStartEnd = e.startNode.isStartEnd || e.endNode.isStartEnd;
      });
      this.realEdges = this.edges.filter(e => !e.isStartEnd);
      this.virtualEdges = this.edges.filter(e => e.isStartEnd);
    }

    const edgesById = MapHelper.toDictionary(this.edges, e => e.id);
    this.variants.forEach(v => {
      v.nodes = v.nodeIds.map(i => this.nodes.get(i));
      v.nodes.unshift(this.nodes.get(0));
      v.nodes.push(this.nodes.get(2147483647));
      v.nodes.forEach(n => n.variants.push(v));
      v.edges = [];

      if (v.edgeIds) {
        v.edges = v.edgeIds.map(i => edgesById.get(i));
        v.edges.forEach(e => e.variants.push(v));
      } else {
        for (let i = 0; i < v.nodes.length; i++) {
          if (i === v.nodes.length - 1) {
            break;
          }
          const edge = v.nodes[i].outgoingEdges.get(v.nodes[i + 1].id);
          if (!edge) {
            this.loggingService.logWarning(`Can't find edge between ${v.nodes[i].id} and ${v.nodes[i + 1].id}`);
          } else {
            v.edges.push(edge);
            edge.variants.push(v);
          }
        }
      }
    });

    this.edges?.forEach(e => {
      e.refreshMarking();
    });
  }

  private buildVariantsTree() {
    this.variantsTree = new Map();
    this.variants.forEach(v => {
      const indexOfDot = v.label.indexOf('.');
      v.groupLabel = indexOfDot >= 0 ? v.label.substring(0, indexOfDot) : null;
      v.sortOrder = parseInt(v.label.substring(v.label.indexOf(' ') + 1), 10);

      if (v.groupLabel == null) {
        this.variantsTree.set(v.label, { variant: v });
      } else {
        if (!this.variantsTree.has(v.groupLabel)) {
          this.variantsTree.set(v.groupLabel, { variant: Variant.getEmptyVariant(v.groupLabel), children: [] });
        }
        this.variantsTree.get(v.groupLabel).children.push(v);
      }
    });

    this.variantsTree.forEach(v => {
      if (v.children != null) {
        v.variant.marking = v.children[0].marking;
        v.variant.sortOrder = parseInt(v.variant.label.substring(v.variant.label.indexOf(' ') + 1), 10);
        v.variant.statistics.yearCosts = v.children.reduce((r, v) => r + v.statistics.yearCosts, 0);
        v.variant.statistics.casesCount = v.children.reduce((r, v) => r + v.statistics.casesCount, 0);
        v.variant.statistics.casesCountPercentage = v.children.reduce((r, v) => r + v.statistics.casesCountPercentage, 0);
        v.variant.statistics.meanDuration = v.children.reduce((r, v) => r + v.statistics.meanDuration * v.statistics.casesCount, 0) / v.variant.statistics.casesCount;
        v.variant.statistics.averageCosts = v.children.reduce((r, v) => r + v.statistics.averageCosts * v.statistics.casesCount, 0) / v.variant.statistics.casesCount;
        v.variant.statistics.minDuration = v.children.reduce((r, v) => Math.min(r, v.statistics.minDuration), v.children[0].statistics.minDuration);
        v.variant.statistics.maxDuration = v.children.reduce((r, v) => Math.max(r, v.statistics.maxDuration), v.children[0].statistics.maxDuration);
        v.variant.statistics.firstOccurrence = v.children.reduce((r, v) => DateHelper.min(r, v.statistics?.firstOccurrence), v.children[0].statistics.firstOccurrence);
        v.variant.statistics.lastOccurrence = v.children.reduce((r, v) => DateHelper.max(r, v.statistics?.lastOccurrence), v.children[0].statistics.lastOccurrence);
        v.variant.statistics.casesCountHistory = v.children[0].statistics.casesCountHistory.map((_, i) => v.children.reduce((r, v) => r + v.statistics.casesCountHistory[i], 0));
        v.variant.statistics.meanDurationHistory = v.children[0].statistics.casesCountHistory.map(
          (_, i) => v.children.reduce((r, v1) => r + v1.statistics.meanDurationHistory[i] * v1.statistics.casesCountHistory[i], 0) / v.variant.statistics.casesCountHistory[i],
        );
      }
    });
  }

  async refreshSelection(): Promise<void> {
    this.reloading = true;
    try {
      let allVariantsAreSelected = this.variants.size > 0 && MapHelper.every(this.variants, v => v.isSelected);
      if (allVariantsAreSelected == null) {
        allVariantsAreSelected = true;
      }

      this.selectedNodes = this.nodes ? MapHelper.filter(this.nodes, n => allVariantsAreSelected || n.variants.some(v => v.isSelected)) : [];
      this.selectedEdges = this.edges?.filter(e => allVariantsAreSelected || e.variants.some(v => v.isSelected)) ?? [];
      this.selectedVariants = MapHelper.filter(this.variants, v => allVariantsAreSelected || v.isSelected);
      await this.updateStatistics();
      await this.calculateNetworkPositions();
      this.calculateHighlights();
    } finally {
      this.reloading = false;
    }
  }

  async calculateNetworkPositions(): Promise<void> {
    const selectedRootNodes = this.selectedNodes.filter(n => n.parentNodeId == null);
    const visibleNodes = new Set(this.selectedNodes.filter(n => n.isVisible).map(n => n.id));

    selectedRootNodes.forEach(n => {
      this.clusterExpandIteration(n, visibleNodes);
    });

    this.positions = await this.graphvizService.calculatePositions(selectedRootNodes, this.selectedNodes, this.selectedEdges);
  }

  private clusterExpandIteration(n: Node, visibleNodes: Set<number>) {
    if (!n?.isExpanded) {
      return;
    }

    if (n.children?.length === 1 && n.children[0].children.length > 0) {
      n.children[0].isExpanded = true;
      this.refreshNodeVisibility(n, true);
      this.selectedEdges.forEach(e => (e.isVisible = e.isStartEnd || (e.startNode.isVisible && e.endNode.isVisible)));
    }
    n.children.forEach(children => this.clusterExpandIteration(children, visibleNodes));
  }

  calculateHighlights() {
    if (!this.selectedNodes || !this.selectedEdges) {
      return;
    }
    const visibleNodes = this.selectedNodes.filter(n => !n.isStartEnd && !n.isExpanded && n.isVisible);
    const visibleNodesSet = new Set(this.selectedNodes.filter(n => n.isVisible).map(n => n.id));
    const visibleEdges = this.selectedEdges.filter(e => e.isStartEnd || (visibleNodesSet.has(e.startNode.id) && visibleNodesSet.has(e.endNode.id)));
    const logsCount = Math.max(...visibleNodes.map(n => n.statistics?.logsCount));
    const casesCount = Math.max(...visibleNodes.map(n => n.statistics?.casesCount));
    const meanDuration = Math.max(...visibleNodes.map(n => n.statistics?.meanDuration));
    // this.averagePrice = Math.max(...visibleNodes.map(n => n.statistics?.averagePrice)); -- not necessary, we can use mean duration as well
    const transfersCount = Math.max(...visibleEdges.map(n => n.statistics?.transfersCount));
    const edgeCasesCount = Math.max(...visibleEdges.map(e => e.statistics?.casesCount));
    const edgeMeanDuration = Math.max(...visibleEdges.map(e => e.statistics?.meanDuration));

    visibleNodes.forEach(n => {
      n.logsCountHighlight = n.statistics.logsCount / logsCount;
      n.casesCountHighlight = n.statistics.casesCount / casesCount;
      n.durationHighlight = n.statistics.meanDuration / meanDuration;
    });

    visibleEdges.forEach(e => {
      e.quantityHighlight = e.statistics.casesCount / edgeCasesCount;
      e.transfersCountHighlight = e.statistics.transfersCount / transfersCount;
      e.durationHighlight = e.statistics.meanDuration / edgeMeanDuration;
    });
  }

  public async showNode(nodeId: number): Promise<void> {
    let node = this.nodes.get(nodeId);
    this.collapseNode(node);
    while (node.parentNodeId != null) {
      node = this.nodes.get(node.parentNodeId);
      node.isExpanded = true;
    }
    this.refreshVisibility();
    await this.calculateNetworkPositions();
  }

  private collapseNode(node: Node) {
    node.isExpanded = false;
    if (node.children) {
      node.children.forEach(nodeChild => this.collapseNode(nodeChild));
    }
  }

  public refreshVisibility(): void {
    this.rootNodes.forEach(n => this.refreshNodeVisibility(n, true));
    this.edges.forEach(e => (e.isVisible = e.startNode.isVisible && e.endNode.isVisible));
    this.storageService.expandedNodeIds = MapHelper.filter(this.nodes, n => n.isExpanded).map(n => n.id);
    this.calculateHighlights();
  }

  private refreshNodeVisibility(node: Node, isVisible: boolean) {
    node.isVisible = isVisible;
    node.isExpanded = isVisible ? node.isExpanded : false;
    node.children.forEach(n => this.refreshNodeVisibility(n, isVisible && node.isExpanded));
  }

  private async updateStatistics() {
    if (!this.process) {
      return;
    }
    const variantIds = this.selectedVariants.map(v => v.id);
    const itemsStatistics = await this.apiService.getItemsStatistics(this.process.id, variantIds);
    this.realNodes.forEach(n => {
      const statistics = itemsStatistics.nodesStatistics[n.id];
      if (statistics != null) {
        n.statistics.logsCount = statistics.logsCount;
        n.statistics.casesCount = statistics.casesCount;
        n.statistics.casesCountPercentage = statistics.casesCountPercentage;
        n.statistics.averageRepetition = statistics.averageRepetition;
        n.statistics.meanDuration = statistics.meanDuration;
        n.statistics.minDuration = statistics.minDuration;
        n.statistics.maxDuration = statistics.maxDuration;
        n.statistics.meanDurationPercentage = statistics.meanDurationPercentage;
        n.statistics.averagePrice = statistics.averagePrice;
        n.statistics.averagePricePercentage = statistics.averagePricePercentage;
        n.statistics.maxRepetition = Math.max(...this.selectedVariants.map(v => v.nodeIds.filter(i => i === n.id).length));
      }
    });
    const firstNodes: Record<number, number> = {};
    const lastNodes: Record<number, number> = {};
    this.selectedVariants.forEach(v => {
      firstNodes[v.nodeIds[0]] = (firstNodes[v.nodeIds[0]] || 0) + v.statistics.casesCount;
      lastNodes[v.nodeIds[v.nodeIds.length - 1]] = (lastNodes[v.nodeIds[v.nodeIds.length - 1]] || 0) + v.statistics.casesCount;
    });
    this.edges.forEach(e => {
      const statistics = itemsStatistics.edgesStatistics[e.id];
      if (statistics != null) {
        e.statistics.transfersCount = statistics.transfersCount;
        e.statistics.casesCount = statistics.casesCount;
        e.statistics.casesCountPercentage = statistics.casesCountPercentage;
        e.statistics.averageRepetition = statistics.averageRepetition;
        e.statistics.meanDuration = statistics.meanDuration;
        e.statistics.minDuration = statistics.minDuration;
        e.statistics.maxDuration = statistics.maxDuration;
        e.statistics.meanDurationPercentage = statistics.meanDurationPercentage;
        e.statistics.averagePrice = statistics.averagePrice;
        e.statistics.averagePricePercentage = statistics.averagePricePercentage;
        e.statistics.maxRepetition = Math.max(...this.selectedVariants.map(v => v.edges.filter(i => i === e).length));
      } else if (e.isStartEnd) {
        // TODO just for compatibility - remove after streaming finished
        const count = e.from === 0 ? firstNodes[e.to] : lastNodes[e.from];
        e.statistics = e.statistics ?? new EdgeStatistics();
        e.statistics.transfersCount = count;
        e.statistics.casesCount = count;
        e.statistics.maxRepetition = 1;
        if (this.process) {
          e.statistics.casesCountPercentage = this.process.statistics.casesCount !== 0 ? count / this.process.statistics.casesCount : 0 ?? 0;
        }
        e.statistics.averageRepetition = 1;
      }
    });
  }

  calculateProcessReplay(): void {
    this.positions.edges.forEach((e: EdgePosition) => {
      e.edge.replayPeriodInSeconds = this.getReplayPeriod(e.edge.quantityHighlight);
      e.edge.replaySpeedInSeconds = this.getReplaySpeed(e.edge.durationHighlight);
    });
  }

  private getReplayPeriod(percentage: number) {
    let period = 2;
    while (period < 16 && percentage < 0.75) {
      period *= 2;
      percentage *= 2;
    }
    return percentage < 0.75 ? 0 : period;
  }

  private getReplaySpeed(percentage: number) {
    let speed = 16;
    while (speed > 2 && percentage < 0.75) {
      speed /= 2;
      percentage *= 2;
    }
    return 2;
  }
}
